<template>
  <div>
    <!-- ///////////////////////////////////////////////////////////////////////////////////////////////////////////// -->
    <!-- menu superior -->
    <!-- blue darken-2 = primary -->
    <v-app-bar
      :color="appBarColor"
      class="mb-3"
      dense
      dark
    >
      <v-btn
        v-if="
          $store.state.login ||
          $store.state.newAccount ||
          $route.meta.title.match(/Forgot/)
        "
        icon
        @click="$router.back()"
      >
        <v-icon size="18px">arrow_back_ios</v-icon>
      </v-btn>
      <v-toolbar-title class="ma-0 pa-0">Gestão XXI</v-toolbar-title>
      <v-spacer></v-spacer>
      {{ $store.state.user.tradingName }}
      <!-- ///////////////////////////////////////////////////////////////////////////////////////////////////////////// -->
      <!-- menu a direita -->
      <v-spacer></v-spacer>
      <v-menu
        left
        bottom
      >
        <template v-slot:activator="{ on }">
          <v-btn
            icon
            v-on="on"
          >
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
          <div v-if="
              ($store.state.enterpriseCounter || $store.state.user.entity == 'supplier' || $store.state.user.entity == 'customer') &&
              ($route.meta.title.match(/Edit/) ||
                $route.meta.title.match(/New/) ||
                $route.meta.title.match(/Address/) ||
                $route.meta.title.match(/Contact/) ||
                $route.meta.title.match(/Bank/) ||
                $route.meta.title.match(/1_access/))
            ">
            <!-- Recent-->
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  v-if="$store.state.user.profile != 'Self-Service'"
                  icon
                  v-on="on"
                  @click="$emit(navbar.recent)"
                >
                  <v-icon>mdi-history</v-icon>
                </v-btn>
              </template>
              <span>
                {{ $t("buttom.Recents") }}
              </span>
            </v-tooltip>
          </div>
          <!-- profile == 'Deliver'  -->
          <div v-if="$store.state.user.profile == 'Deliver' && !$route.meta.title.match(/User/) &&
              ($route.meta.title.match(/Edit/) ||  $route.meta.title.match(/New/))">
            <!-- Recent-->
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  icon
                  v-on="on"
                  @click="$emit(navbar.recent)"
                >
                  <v-icon>mdi-history</v-icon>
                </v-btn>
              </template>
              <span>
                {{ $t("buttom.My Orders") }}
              </span>

            </v-tooltip>
          </div>
        </template>

        <!-- ///////////////////////////////////////////////////////////////////////////////////////////////////////////// -->
        <!-- menu a dots -->
        <v-list>
          <v-subheader>
            <div v-if="$store.state.user.profile != 'Deliver' && !$route.query.delivery">
              {{ $t("common." + $route.meta.title) }}
            </div>
            <div v-else>
              {{ $t("common.deliver" ) }}
            </div>
          </v-subheader>
          <v-list-item-group color="primary">
            <v-list-item
              v-for="(item, i) in navbar.menu"
              :key="i"
              @click="$emit(item.action)"
              router
              :to="item.route"
            >
              <v-list-item-icon>
                <v-icon v-text="item.icon"></v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <!-- <v-list-item-title v-text="item.text"></v-list-item-title> -->
                <v-list-item-title v-text="$t('menu.' + item.text)"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-menu>
      <!-- parametrizar -->
      <!-- https://stackoverflow.com/questions/49721710/how-to-use-vuetify-tabs-with-vue-router -->
      <!-- <v-tab v-for="tab in tabs" :key="tab.id" :to="tab.route" exact> -->
      <template
        v-slot:extension
        v-if="
          ($route.meta.title.match(/Edit User/)  && $store.state.user.profile != 'Deliver') ||
          $route.meta.title.match(/User Access/) ||
          $route.meta.title.match(/User Logins/) ||
          $route.meta.title == 'Edit Customer' ||
          $route.meta.title == 'Customer Commercial' ||
          $route.meta.title == 'Edit Enterprise' ||
          $route.meta.title == 'Enterprise Consumer' ||
          $route.meta.title == 'Edit Product' ||
          $route.meta.title == 'Product - Consumer Sales' ||

          $route.meta.title.match(/Layout/) ||
          $route.meta.title.match(/Imprint/)
        "
      >
        <v-tabs
          align-with-title
          v-if="$route.meta.title.match(/User/)"
        >
          <v-tab to="/user">{{ $t("menu.User") }}</v-tab>
          <v-tab to="/access">{{ $t("menu.Accesses") }}</v-tab>
          <v-tab to="/logins">{{ $t("menu.Logins") }}</v-tab>
        </v-tabs>
        <v-tabs
          align-with-title
          v-if="$route.meta.title == 'Edit Customer' || $route.meta.title == 'Customer Commercial'"
        >
          <v-tab to="/customer">{{ $t("common.Customer") }}</v-tab>
          <v-tab to="/customer_commercial">{{ $t("common.Commercial") }}</v-tab>
        </v-tabs>

        <v-tabs
          align-with-title
          v-if="$route.meta.title == 'Edit Enterprise' || $route.meta.title == 'Enterprise Consumer'"
        >
          <v-tab to="/enterprise">{{ $t("common.Enterprise") }}</v-tab>
          <v-tab to="/enterprise_module">{{ $t("common.Modules") }}</v-tab>
        </v-tabs>

        <v-tabs
          align-with-title
          v-if="$store.state.user.consumerModule && $route.meta.title == 'Edit Product' || $route.meta.title == 'Product - Consumer Sales'"
        >
          <v-tab to="/product">{{ $t("common.Product") }}</v-tab>
          <v-tab to="/product_consumer">{{ $t("common.Consumer") }}</v-tab>
        </v-tabs>

        <v-tabs
          align-with-title
          v-if="$route.meta.title.match(/Layout/)"
        >
          <v-tab to="/layout_model">{{ $t("common.Layout") }}</v-tab>
          <v-tab to="/layout_imprint">{{ $t("common.Print") }}</v-tab>
        </v-tabs>

        <router-view></router-view>

      </template>
    </v-app-bar>

    <!-- btnGoTop -->
    <v-btn
      v-scroll="onScroll"
      v-show="btnGoTop"
      fab
      dark
      fixed
      bottom
      right
      color="primary"
      small
      style="opacity: 0.5"
      @click="toTop()"
    >
      <v-icon>keyboard_arrow_up</v-icon>
    </v-btn>

  </div>
  <!-- ///////////////////////////////////////////////////////////////////////////////////////////////////////////// -->
</template>

<script>
var packageJson = require("../../../../../package.json");

export default {
  name: "Navbar",
  props: {
    navbar: {},
  },
  ////////
  data: () => ({
    windowWidth: 0,
    appBarColor: "",
    btnGoTop: false,
  }),
  /////////
  methods: {
    /////////////
    onScroll(e) {
      if (typeof window === "undefined") return;
      const top = window.pageYOffset || e.target.scrollTop || 0;
      this.btnGoTop = top > 20;
    },
    /////////
    toTop() {
      this.$vuetify.goTo(0);
    },
  },
  /////////
  created() {
    this.windowWidth = window.innerWidth;
    // alert(" windowWidth = " + this.windowWidth);
    this.appBarColor = packageJson.app_bar_color;
  },
};
</script>
