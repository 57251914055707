// front-end call api
import i18n from '@/i18n'

import {
  api
} from "@/services.js";


/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///  SETTING  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////
export function selectSettingPage(pageId) {
  return api
    .get(`/setting/page/?pageId=${pageId}`)
    .then((response) => {
      sessionStorage.setItem(
        pageId + "Setting",
        JSON.stringify(response.data)
      );
      // console.log("Setting = " + JSON.stringify(response.data));
    })
    .catch((err) => {
      console.info("Api error => selectSettingPage-" + pageId + " " + err);
    });
};

/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///  PREFER  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////
export function selectPreferPage(groupUser, pageId) {
  return api
    .get(`/prefer/?pageId=${pageId}`)
    .then((response) => {
      localStorage.setItem(
        groupUser +
        pageId + "Prefer",
        JSON.stringify(response.data)
      );
      // used later to compare if the prefer has changed
      localStorage.setItem(
        groupUser +
        pageId + "PreferPrevious",
        JSON.stringify(response.data)
      );
      // console.log("Prefer = " + JSON.stringify(response.data));
    })
    .catch((err) => {
      console.info("Api error => selectPrefer-" + pageId + " " + err);
    });
};

/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///  LAYOUT MODEL  //////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////
export function selectLayoutModel(groupUser, layoutId, layoutModelId, modelId, viewProductSupplier) {
  // i18n
  const t = i18n.t.bind(i18n);

  let layout = {
    autoTableSrc: false
  };
  let selectedHeaders = []; // Header PurchaseGrid.vue
  let imprintPage = []; // PDF
  let headerAutoTable = []; // PDF Header Grid
  //
  api
    .get(
      `/layout/model/?layoutId=${layoutId}&layoutModelId=${layoutModelId}&purpose=2&modelId=${modelId}&viewProductSupplier=${viewProductSupplier}` // 1=maintenance 2=display
    )
    .then((response) => {
      let headers = response.data.layoutModel;
      //
      let order = 0;
      let gridAlreadyMarked = false;
      // selectedHeaders ///////////////////////////////////
      for (let i = 0; i < headers.length; ++i) {
        let item = {};

        if (headers[i].visible || headers[i].imprint) {
          item.value = headers[i].value;
          // Title
          if (headers[i].title) {
            item.text = headers[i].title;
          } else {
            item.text = t(headers[i].label + "." + headers[i].text);
            // item.text = headers[i].text;

          }
          item.align = headers[i].align;
          item.sortable = headers[i].sortable;
          item.imprint = headers[i].imprint;
          item.grid = headers[i].grid;
          //
          if (
            headers[i].grid &&
            (headers[i].visible || headers[i].imprint) && item.value != "descriptionComplementary"
          ) {
            ///////////////////////////////////////////////////////
            // header v-data-table
            if (headers[i].visible) {
              selectedHeaders.push(item);
            }
            ///////////////////////////////////////////////////////
            // header jsPDF autotable
            if (headers[i].imprint) {
              let dataKey = item.value;
              dataKey = dataKey.replace(
                "dateDelivery",
                "dateDeliveryLocale"
              );
              dataKey = dataKey.replace(
                "dateNeed",
                "dateNeedLocale"
              );
              dataKey = dataKey.replace(
                "dateDeadline",
                "dateDeadlineLocale"
              );

              if (dataKey === "src") {
                dataKey = "srcX";
                layout.autoTableSrc = true;
              }
              const column = {
                title: item.text,
                dataKey: dataKey
              };
              headerAutoTable.push(column);
            }
          }
          if (headers[i].imprint) {
            ///////////////////////////////////////////////////////
            // jsPDF
            if (
              !headers[i].grid ||
              (headers[i].grid && !gridAlreadyMarked)
            ) {
              order = order + 1;
              item.order = order;
              imprintPage.push(item);
              if (headers[i].grid) gridAlreadyMarked = true;
            }
          }
        }
      }
      //////////////////////////////////////////////////////
      // props /////////////////////////////////////////////
      // Layout Page
      layout.selectedHeaders = selectedHeaders;
      layout.displayGrid = response.data.displayGrid;
      layout.displayPage = response.data.displayPage;
      // Layout PDF
      layout.layout = response.data.layout;
      layout.imprintPage = imprintPage;
      layout.headerAutoTable = headerAutoTable;
      //  localStorage
      localStorage.setItem(groupUser + layoutId + "Layout", JSON.stringify(layout));
      //
    })
    .catch((err) => {
      console.info("Api error => selectLayoutModel-" + layoutId + " " + err);

    });
};

/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///  ENTERPRISE  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////
export function consultEnterprise(pageId, companyId) {
  return api
    .get(`/enterprise/?companyId=${companyId}`)
    .then((response) => {
      // console.log(response.data);
      sessionStorage.setItem(
        // user in pdf header
        "enterprise",
        JSON.stringify(response.data)
      );
    })
    .catch((err) => {
      console.info("Api error => consultEnterprise-" + pageId + " " + err);
    });
};
