import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VSubheader } from 'vuetify/lib/components/VSubheader';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
import Scroll from 'vuetify/lib/directives/scroll';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VAppBar,{staticClass:"mb-3",attrs:{"color":_vm.appBarColor,"dense":"","dark":""},scopedSlots:_vm._u([(
        (_vm.$route.meta.title.match(/Edit User/)  && _vm.$store.state.user.profile != 'Deliver') ||
        _vm.$route.meta.title.match(/User Access/) ||
        _vm.$route.meta.title.match(/User Logins/) ||
        _vm.$route.meta.title == 'Edit Customer' ||
        _vm.$route.meta.title == 'Customer Commercial' ||
        _vm.$route.meta.title == 'Edit Enterprise' ||
        _vm.$route.meta.title == 'Enterprise Consumer' ||
        _vm.$route.meta.title == 'Edit Product' ||
        _vm.$route.meta.title == 'Product - Consumer Sales' ||

        _vm.$route.meta.title.match(/Layout/) ||
        _vm.$route.meta.title.match(/Imprint/)
      )?{key:"extension",fn:function(){return [(_vm.$route.meta.title.match(/User/))?_c(VTabs,{attrs:{"align-with-title":""}},[_c(VTab,{attrs:{"to":"/user"}},[_vm._v(_vm._s(_vm.$t("menu.User")))]),_c(VTab,{attrs:{"to":"/access"}},[_vm._v(_vm._s(_vm.$t("menu.Accesses")))]),_c(VTab,{attrs:{"to":"/logins"}},[_vm._v(_vm._s(_vm.$t("menu.Logins")))])],1):_vm._e(),(_vm.$route.meta.title == 'Edit Customer' || _vm.$route.meta.title == 'Customer Commercial')?_c(VTabs,{attrs:{"align-with-title":""}},[_c(VTab,{attrs:{"to":"/customer"}},[_vm._v(_vm._s(_vm.$t("common.Customer")))]),_c(VTab,{attrs:{"to":"/customer_commercial"}},[_vm._v(_vm._s(_vm.$t("common.Commercial")))])],1):_vm._e(),(_vm.$route.meta.title == 'Edit Enterprise' || _vm.$route.meta.title == 'Enterprise Consumer')?_c(VTabs,{attrs:{"align-with-title":""}},[_c(VTab,{attrs:{"to":"/enterprise"}},[_vm._v(_vm._s(_vm.$t("common.Enterprise")))]),_c(VTab,{attrs:{"to":"/enterprise_module"}},[_vm._v(_vm._s(_vm.$t("common.Modules")))])],1):_vm._e(),(_vm.$store.state.user.consumerModule && _vm.$route.meta.title == 'Edit Product' || _vm.$route.meta.title == 'Product - Consumer Sales')?_c(VTabs,{attrs:{"align-with-title":""}},[_c(VTab,{attrs:{"to":"/product"}},[_vm._v(_vm._s(_vm.$t("common.Product")))]),_c(VTab,{attrs:{"to":"/product_consumer"}},[_vm._v(_vm._s(_vm.$t("common.Consumer")))])],1):_vm._e(),(_vm.$route.meta.title.match(/Layout/))?_c(VTabs,{attrs:{"align-with-title":""}},[_c(VTab,{attrs:{"to":"/layout_model"}},[_vm._v(_vm._s(_vm.$t("common.Layout")))]),_c(VTab,{attrs:{"to":"/layout_imprint"}},[_vm._v(_vm._s(_vm.$t("common.Print")))])],1):_vm._e(),_c('router-view')]},proxy:true}:null],null,true)},[(
        _vm.$store.state.login ||
        _vm.$store.state.newAccount ||
        _vm.$route.meta.title.match(/Forgot/)
      )?_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){return _vm.$router.back()}}},[_c(VIcon,{attrs:{"size":"18px"}},[_vm._v("arrow_back_ios")])],1):_vm._e(),_c(VToolbarTitle,{staticClass:"ma-0 pa-0"},[_vm._v("Gestão XXI")]),_c(VSpacer),_vm._v(" "+_vm._s(_vm.$store.state.user.tradingName)+" "),_c(VSpacer),_c(VMenu,{attrs:{"left":"","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c(VBtn,_vm._g({attrs:{"icon":""}},on),[_c(VIcon,[_vm._v("mdi-dots-vertical")])],1),(
            (_vm.$store.state.enterpriseCounter || _vm.$store.state.user.entity == 'supplier' || _vm.$store.state.user.entity == 'customer') &&
            (_vm.$route.meta.title.match(/Edit/) ||
              _vm.$route.meta.title.match(/New/) ||
              _vm.$route.meta.title.match(/Address/) ||
              _vm.$route.meta.title.match(/Contact/) ||
              _vm.$route.meta.title.match(/Bank/) ||
              _vm.$route.meta.title.match(/1_access/))
          )?_c('div',[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [(_vm.$store.state.user.profile != 'Self-Service')?_c(VBtn,_vm._g({attrs:{"icon":""},on:{"click":function($event){return _vm.$emit(_vm.navbar.recent)}}},on),[_c(VIcon,[_vm._v("mdi-history")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("buttom.Recents"))+" ")])])],1):_vm._e(),(_vm.$store.state.user.profile == 'Deliver' && !_vm.$route.meta.title.match(/User/) &&
            (_vm.$route.meta.title.match(/Edit/) ||  _vm.$route.meta.title.match(/New/)))?_c('div',[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c(VBtn,_vm._g({attrs:{"icon":""},on:{"click":function($event){return _vm.$emit(_vm.navbar.recent)}}},on),[_c(VIcon,[_vm._v("mdi-history")])],1)]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("buttom.My Orders"))+" ")])])],1):_vm._e()]}}])},[_c(VList,[_c(VSubheader,[(_vm.$store.state.user.profile != 'Deliver' && !_vm.$route.query.delivery)?_c('div',[_vm._v(" "+_vm._s(_vm.$t("common." + _vm.$route.meta.title))+" ")]):_c('div',[_vm._v(" "+_vm._s(_vm.$t("common.deliver" ))+" ")])]),_c(VListItemGroup,{attrs:{"color":"primary"}},_vm._l((_vm.navbar.menu),function(item,i){return _c(VListItem,{key:i,attrs:{"router":"","to":item.route},on:{"click":function($event){return _vm.$emit(item.action)}}},[_c(VListItemIcon,[_c(VIcon,{domProps:{"textContent":_vm._s(item.icon)}})],1),_c(VListItemContent,[_c(VListItemTitle,{domProps:{"textContent":_vm._s(_vm.$t('menu.' + item.text))}})],1)],1)}),1)],1)],1)],1),_c(VBtn,{directives:[{def: Scroll,name:"scroll",rawName:"v-scroll",value:(_vm.onScroll),expression:"onScroll"},{name:"show",rawName:"v-show",value:(_vm.btnGoTop),expression:"btnGoTop"}],staticStyle:{"opacity":"0.5"},attrs:{"fab":"","dark":"","fixed":"","bottom":"","right":"","color":"primary","small":""},on:{"click":function($event){return _vm.toTop()}}},[_c(VIcon,[_vm._v("keyboard_arrow_up")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }