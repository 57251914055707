// front-end
import {
  postalCodeList
} from "@/json/postalCodeData.js";


export function timeBetweenDates(start, end) {
  // console.log("start = ", start);
  // console.log("end   = ", end);

  if (!start) {
    return null
  };

  start = new Date(start);
  if (end) {
    end = new Date(end);
  } else {
    end = new Date();
  }

  var difference = new Date(end - start);
  var elapsed = "";
  let hours = difference.getUTCHours();
  // console.log("hours", hours);
  if (hours > 0) {
    elapsed = hours + "h ";
  }

  elapsed += difference.getUTCMinutes() + "m ";
  // elapsed += difference.getUTCSeconds() + "s ";
  // console.log(elapsed);

  return elapsed;
};

/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export function convertSecondsToHoursAndMinutes(seconds, locale) {
  // console.log("locale = " + JSON.stringify(locale));

  // console.log("2 seconds           = " + seconds);
  const totalMinutes = Math.round(seconds / 60);
  // console.log("3 totalMinutes           = " + totalMinutes);
  if (Number(totalMinutes) < 60) return totalMinutes + " min";
  //
  var hours = Math.floor(totalMinutes / 60);
  hours = hours.toString().padStart(2, "0");
  var minutes = (totalMinutes % 60).toString().padStart(2, "0");
  //
  return hours + " h " + minutes + " min";;
}

/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export function validatePostalCode(postalCode, country) {
  var obj = postalCodeList();

  let index = obj.findIndex(x => x.code === country);
  if (index == -1) return "";

  let reg = obj[index].reg
  // console.log("reg = " + reg);
  var pattern = new RegExp(reg);
  if (!pattern.test(postalCode)) return "Invalid Postal Code";
  return "";

};


/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export function validateGtin(gtin) {
  // console.log("gtin = " + gtin);

  if (gtin.length !== 8 && gtin.length !== 13) return "GTIN valid ean8 or ean13"

  var checkSum = gtin.split("").reduce(function (p, v, i) {
    return i % 2 == 0 ? p + 1 * v : p + 3 * v;
  }, 0);
  if (checkSum % 10 != 0) {
    return "Invalid GTIN";
  } else {
    return "";
  }
};

/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export function formatCnpjCpf(value) {
  const cnpjCpf = value.replace(/\D/g, '');

  if (cnpjCpf.length === 11) {
    return cnpjCpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, "\$1.\$2.\$3-\$4");
  }

  return cnpjCpf.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, "\$1.\$2.\$3/\$4-\$5");
};

/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export function firstUpperCase(value) {
  if (!value) return ''
  if (value.trim() == '') return ''

  // implemtada a partir  https://pt.stackoverflow.com/questions/32086/converter-cada-primeira-letra-de-cada-palavra-em-maiu%CC%81sculas/32440
  let loweredText = value.toLowerCase()
  loweredText = loweredText.replace(/ {3}/g, ' ') // NEGRAO   FILIAL  RESENDE- RJ
  loweredText = loweredText.replace(/ {2}/g, ' ')

  const words = loweredText.split(' ')
  const ignore = ['e', 'a', 'de', 'da', 'das', 'do', 'dos', 'xxi']
  // console.log('0 - words.length = ' + words.length);
  // console.log('loweredText = ' + loweredText);

  for (let i = 0; i < words.length; i++) {
    let word = words[i]
    const firstLetter = word[0]

    if (ignore.indexOf(word) === -1) {
      word = firstLetter.toUpperCase() + word.slice(1)
    } else {
      word = firstLetter + word.slice(1)
    };
    words[i] = word
  }
  return words.join(' ')
};

/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export function cleanObject(o) {
  // https://stackoverflow.com/questions/684575/how-to-quickly-clear-a-javascript-object
  for (const [key, value] of Object.entries(o)) {
    const propType = typeof o[key]
    // console.log(key, value, propType);
    switch (propType) {
      case 'number':
        o[key] = 0
        break
      case 'string':
        o[key] = ''
        break
      case 'boolean':
        o[key] = false
        break
      case 'undefined':
        o[key] = undefined
        break
      default:
        if (value === null) {
          continue
        }
        cleanObject(o[key])
        break
    }
  }
};
