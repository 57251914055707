import { VAlert } from 'vuetify/lib/components/VAlert';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('nav',[_c('Navbar',{attrs:{"navbar":_vm.navbar}})],1),_c('main',{class:{
      'ml-12': _vm.$vuetify.breakpoint.mdAndUp,
      'ml-2': _vm.$vuetify.breakpoint.smAndDown,
      'me-12': _vm.$vuetify.breakpoint.mdAndUp,
      'me-2': _vm.$vuetify.breakpoint.smAndDown,
    }},[_c(VContainer,{attrs:{"fluid":""}},[_c(VCard,{staticClass:"mx-auto",attrs:{"flat":"","max-width":"600","tile":""}},[_c(VRow,{staticClass:"mt-4 mb-0"},[_c(VCol,{attrs:{"cols":"6"}},[_c(VRow,[_c(VImg,{attrs:{"src":require("@/assets/logo/logo_gestao_xxi_horizontal.jpg"),"contain":"","max-height":"60"}})],1)],1)],1),_c(VRow,[_c('h1',{staticClass:"headline titleH1"},[_vm._v(" "+_vm._s(_vm.$t("common." + _vm.$route.meta.title))+" ")])]),_c(VAlert,{attrs:{"type":"info","border":"left","text":"","transition":"slide-y-reverse-transition"}},[_vm._v(_vm._s(_vm.$t("user.titleHelpChangePassword")))]),_c(VForm,{ref:"form",model:{value:(_vm.formValid),callback:function ($$v) {_vm.formValid=$$v},expression:"formValid"}},[_c(VCard,{attrs:{"flat":""}},[_c('Password',{attrs:{"password":_vm.password}})],1),_c(VSnackbar,{attrs:{"top":"top","color":_vm.snackbar.color,"timeout":_vm.snackbar.timeout},model:{value:(_vm.snackbar.show),callback:function ($$v) {_vm.$set(_vm.snackbar, "show", $$v)},expression:"snackbar.show"}},[_vm._v(_vm._s(_vm.snackbar.text))]),_c(VCard,{attrs:{"flat":""}},[_c('MainButton',{attrs:{"mainButton":_vm.mainButton},on:{"save":_vm.save}})],1)],1)],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }