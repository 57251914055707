<template>
  <div>
    <nav>
      <!-- NAVBAR-->
      <Navbar :navbar="navbar"></Navbar>
    </nav>
    <!-- <main> -->
    <v-container fluid>
      <v-card
        flat
        class="mx-auto"
        max-width="700"
        tile
      >
        <v-row class="ml-2 mt-4 mb-0">
          <v-col cols="6">
            <v-row>
              <v-img
                src="@/assets/logo/logo_gestao_xxi_horizontal.jpg"
                contain
                max-height="70"
              ></v-img>
            </v-row>
          </v-col>
        </v-row>

        <v-row dense>
          <v-col cols="4"></v-col>
          <v-col cols="8">
            <v-row
              align="end"
              justify="end"
            >
              <v-checkbox
                :label="$t('login.Choose your language')"
                v-model="showLanguage"
                class="mt-0 me-4"
              ></v-checkbox>
            </v-row>

            <v-card
              v-if="showLanguage"
              class="mt-0 me-4"
              tile
            >
              <v-list>
                <v-subheader>{{ $t("login.Language") }}</v-subheader>
                <v-divider></v-divider>

                <v-list-item-group
                  v-model="indexLanguage"
                  color="primary"
                  dense
                >
                  <v-list-item
                    v-for="item in languages"
                    :key="item.locale"
                    @click="changeLocale(item)"
                  >
                    <v-list-item-icon>
                      <flag
                        :iso="item.flag"
                        v-bind:squared="false"
                      />
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title> {{item.language}}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-card>
          </v-col>
        </v-row>

        <v-col cols="12">
          <h1
            v-if="!pageOrigin"
            class="headline titleH1"
          >{{ $t("login.title") }} </h1>
          <h1
            v-if="pageOrigin"
            class="headline titleH1"
          > {{ $t("login.title") }} - {{ $t("common." + pageOrigin) }} {{ $route.params.tradingName }}</h1>

        </v-col>

        <!-- FORM -->
        <v-form
          v-model="formValid"
          ref="form"
        >
          <v-divider class="mb-4"></v-divider>

          <div>
            <v-col cols="12">
              <!-- "e-mail" -->
              <v-text-field
                :label="$t('user.email *')"
                v-model="email"
                class="ml-2 me-2"
                id="email"
                name="email"
                type="email"
                maxlength="99"
                append-icon="mdi-email-outline"
                @keyup.enter="loginUser()"
                :rules="[rules.emailRules()]"
                validate-on-blur
              ></v-text-field>
            </v-col>

            <v-col cols="12">
              <v-text-field
                :label="$t('user.Password *')"
                v-model="password"
                class="ml-2 me-2"
                id="password"
                name="password"
                maxlength="30"
                :type="showPassword ? 'text' : 'password'"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :hint="$t('login.At least 8 characters')"
                counter
                @click:append="showPassword = !showPassword"
                @keypress="checkQuote($event)"
                @keyup.enter="loginUser()"
                :rules="[(v) => !!v || $t('rules.Required Field')]"
              ></v-text-field>
            </v-col>

            <v-row>
              <v-spacer></v-spacer>
              <v-btn
                text
                small
                class="me-5 mt-3"
                color="primary"
                @click="goForgotMyPassword()"
              >{{ $t("login.Forgot my Password") }}</v-btn>
            </v-row>

            <!-- Entry -->
            <v-card
              class="ml-2 mt-4 me-2"
              flat
            >
              <v-col cols="12">
                <v-btn
                  block
                  tile
                  outlined
                  color="primary"
                  :disabled="btnEnterDisabled"
                  @click.prevent="loginUser()"
                >
                  <div>
                    <v-icon left>lock_open</v-icon>
                    <!-- save -->
                    {{ $t("login.Enter") }}
                  </div>
                </v-btn>
              </v-col>
              <v-card
                flat
                v-if="btnEnterDisabled"
                class="ml-2 mt-0 me-2"
              >
                <v-progress-linear
                  indeterminate
                  color="primary"
                ></v-progress-linear>
              </v-card>
              <!-- Keep Connected
              <v-row>
                <v-spacer></v-spacer>
                <v-switch :label="$t('login.Keep Connected')" class="me-6 mt-4"></v-switch>
              </v-row>-->
            </v-card>

            <v-divider class="mt-4"></v-divider>

            <div v-if="counterAccount">
              <br>
              <br>
              <br>
              <br>
              <br>
              <br>
              <br>
              <br>
              <br>
              <br>
              <br>
              <br>
            </div>

            <v-card
              v-if="pageOrigin != 'Deliver' && packageJson.name != 'gestaoxxi-app-delivery'"
              class="ml-2 mt-12"
              flat
            >
              <v-col cols="12">
                <v-btn
                  block
                  tile
                  outlined
                  color="primary"
                  @click="goNewAccount()"
                >
                  <v-icon
                    size="18px"
                    left
                  >account_circle</v-icon>
                  {{ $t("login.Create New Account and Company") }}
                </v-btn>
              </v-col>
            </v-card>

            <!-- snackbar -->
            <v-snackbar
              v-model="snackbar.show"
              top="top"
              :color="snackbar.color"
              :timeout="snackbar.timeout"
            >{{ snackbar.text }}</v-snackbar>
          </div>
        </v-form>

        <!-- /////////////////////////  ENTERPRISES  & ADDRESS /////////////////////////////////////////////////////////////////////////// -->
        <v-row justify="center">
          <v-dialog
            v-model="dialog"
            persistent
            max-width="660"
          >
            <v-toolbar
              color="cyan"
              dark
            >
              <v-toolbar-title>
                <div v-if="!informUserAddress">
                  {{ $t("enterprise.Select a Company") }}
                </div>
                <div v-if="informUserAddress">
                  {{ $t("deliver.Enter your Delivery Address :") }}
                </div>

              </v-toolbar-title>
            </v-toolbar>

            <v-card>
              <v-list
                two-line
                subheader
                v-if="enterprises.length > 0"
              >
                <v-list-item-group
                  color="primary"
                  v-if="!informUserAddress"
                >
                  <v-list-item
                    v-for="item in enterprises"
                    :key="item.groupId.toString() + item.companyId.toString() "
                    @click.prevent="loginEnterprise(item)"
                  >
                    <v-list-item-avatar
                      size="40"
                      class="ml-0"
                    >
                      <v-img
                        :src="item.logoUrl"
                        contain
                      ></v-img>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title>{{item.companyName}}</v-list-item-title>
                      <v-list-item-subtitle
                        v-if="item.profile"
                        class="primary--text"
                      >{{ $t("common." + item.profile)}}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider></v-divider>
                </v-list-item-group>
              </v-list>
              <!-- ADDRESS -->
              <v-card
                flat
                v-if="informUserAddress"
                class="mt-3"
              >
                <span class="ml-8 font-weight-medium"> {{ this.companyName}}</span>
                <Address
                  :address="address"
                  ref="myAddress"
                ></Address>
              </v-card>

              <!-- <v-divider inset></v-divider> -->
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  v-if="informUserAddress && address.address"
                  text
                  color="primary"
                  class="me-4"
                  @click="saveAddress()"
                  :disabled="btnEnterDisabled"
                >
                  <v-icon
                    size="16px"
                    left
                  >mdi-pencil</v-icon>
                  {{ $t("buttom.Save") }}
                </v-btn>

                <v-btn
                  text
                  color="primary"
                  class="ml-4 me-4"
                  @click="dialog = false; clearAddrees()"
                >
                  <v-icon
                    size="16px"
                    left
                  >close</v-icon>
                  {{$t("buttom.Cancel")}}</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
        <br />

        <small
          v-if="pageOrigin != 'Deliver'"
          class="ml-5"
          style="color: #24b47e"
          id="copyright"
        >Copyright&copy; {{ packageJson.description }} - v{{ packageJson.version }}</small>
      </v-card>
    </v-container>
    <!-- </main> -->
  </div>
</template>
<script>
import i18n from "@/i18n.js";
//
import Address from "@/app/3_company/components/entity/Address.vue";
import Navbar from "@/app/2_common/components/page/Navbar.vue";
// import Address from "../../../../../../client/src/app/3_company/components/entity/Address.vue";
// import Navbar from "../../../../../../client/src/app/2_common/components/page/Navbar.vue";

//
import { api } from "@/services.js";
import { getIp } from "@/services.js";
import { getIpify } from "@/services.js";

var platform = require("platform");
var packageJson = require("../../../../../package.json");

export default {
  name: "Login",
  components: {
    Address,
    Navbar,
    //
  },
  data() {
    return {
      companyName: "",
      informUserAddress: false,
      pageOrigin: "", // Deliver
      groupIdOrigin: "",
      companyIdOrigin: "",
      counterAccount: "",
      //
      enterprises: [],
      dialog: false,
      //
      email: "",
      password: "",
      groupId: 0,
      companyId: 0,
      //
      showPassword: false,
      //
      delay: 100,
      // standard
      formValid: true,
      btnEnterDisabled: false,
      //
      showLanguage: false,
      indexLanguage: -1,
      browserLocale: "",
      //
      packageJson: packageJson,

      userInfo: {
        userIp: "",
        countryCode: "",
        country: "",
        region: "",
        regionName: "",
        city: "",
        latitudeIp: "",
        longitudeIp: "",
        navigator: "",
        device: "",
        operationalSystem: "",
        appVersion: "",
      },

      languages: [
        // { flag: "in", locale: "bn", language: "Bengali" },
        // { flag: "cn", locale: "zh", language: "Chinese" }, // ok
        { flag: "us", locale: "en-us", language: "English" },
        // { flag: "fr", locale: "fr", language: "French" },
        // { flag: "de", locale: "de", language: "German" },
        // { flag: "in", locale: "hi", language: "Hindi" },
        // { flag: "it", locale: "it", language: "Italian" },
        // { flag: "jp", locale: "ja", language: "Japanese" },
        // { flag: "pt", locale: "pt", language: "Portuguese" },
        { flag: "br", locale: "pt-br", language: "Portuguese Brazil" },
        // { flag: "in", locale: "pa", language: "Punjabi" },
        // { flag: "ru", locale: "ru", language: "Russian" }, // ok
        { flag: "es", locale: "es", language: "Spanish" },
        // { flag: "ar", locale: "es-ar", language: "Spanish Argentina" },
      ],

      /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
      ///  PROPS     //////////////////////////////////////////////////////////////////////////////////////////////////////////////////
      /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
      // Props Address
      address: {
        entityId: "",
        cepConsult: "",
        addressId: 0,
        foreignerAd: false,
        countryCode: "",
        address: "",
        number: "",
        complement: "",
        neighborhood: "",
        city: "",
        state: "",
        postalCode: "",
        //
        addressType: "",
        addressTypeIcon: "",
        reference: "",
        region: "",
        latitude: null,
        longitude: null,
        distance: null,
        duration: null,
        processDistance: false,
        //
        observationAd: "",
        inactiveAd: false,
        orderAd: "",
        update: false,
        //
        formValid: true,
      },

      // Props Navbar
      navbar: {
        menu: [
          {
            text: "Help",
            icon: "mdi-help-circle-outline",
            action: "",
            route: "",
          },
        ],
      },
      // snackbar
      snackbar: {
        show: false,
        color: "",
        text: "",
        timeout: 3000,
      },

      /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
      ///  RULES     //////////////////////////////////////////////////////////////////////////////////////////////////////////////////
      /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
      rules: {
        emailRules: (value) => {
          let email = this.email.toLowerCase();
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          if (!pattern.test(email)) return this.$t("rules.Invalid email");

          if (email.indexOf("'") > 0) return this.$t("rules.Invalid email");

          this.email = email;
          return true;
        },
      },
    };
  },
  methods: {
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ///  JS     /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    changeLocale(item) {
      // console.log("item.locale = " + item.locale);
      let locale = item.locale;
      if (locale === "pt") locale = "pt-br";
      if (locale === "es-ar") locale = "es";
      i18n.locale = locale;
      //
      sessionStorage.locale = item.locale;
      localStorage.locale = item.locale; // New Account Activation
      this.browserLocale = item.locale; // back-end
      localStorage.language = item.language;
      this.showLanguage = false;
    },
    //////////////////////
    goForgotMyPassword() {
      //
      this.$router.push({
        name: "forgotMyPassword",
        params: {
          email: this.email,
        },
      });
    },

    //////////////
    goNewAccount() {
      this.$store.commit("UPDATE_NEW_ACCOUNT", true);
      //
      this.$router.push({
        name: "userNewAccount",
        params: {
          userId: "",
          pageId: "login",
        },
      });
    },

    ////////////////////////
    loginEnterprise(item) {
      // console.log(" item = " + JSON.stringify(item));
      //
      this.groupId = item.groupId;
      this.companyId = item.companyId;
      //
      if (item.profile == "Deliver" && !item.addressId) {
        this.companyName = item.companyName;
        // used in user_address_distance
        this.groupIdOrigin = item.groupId;
        this.companyIdOrigin = item.companyId;
        //
        this.address.countryCode = item.countryCode;
        this.address.latitudeOrigin = item.companyLat;
        this.address.longitudeOrigin = item.companyLng;
        this.informUserAddress = true;
        return;
      }
      // console.log(" this.enterprises = " + JSON.stringify(this.enterprises));
      this.$store.commit("UPDATE_ENTERPRISES", this.enterprises);
      //
      this.loginUser();
    },

    //////////////
    async saveAddress() {
      if (!this.$refs.form.validate() || !this.address.formValid) {
        // snackbar
        window.scrollTo(0, 0);
        this.snackbar.color = "warning";
        window.scrollTo(0, 0);
        this.snackbar.text = this.$t("message.alertTyping");
        this.snackbar.show = true;
        return;
      }

      // apiGeocoder ////////////////////////////////////////////////////
      if (!sessionStorage.getItem("apiGeocoder")) {
        let delay = await this.later(1500); // waits for the api google
      }
      if (sessionStorage.getItem("apiGeocoder")) {
        let geocoder = JSON.parse(sessionStorage.getItem("apiGeocoder"));
        this.address.latitude = geocoder.destination.lat;
        this.address.longitude = geocoder.destination.lng;
        //
        if (geocoder.distance && geocoder.distance.value) {
          this.address.processDistance = true;
          this.address.distance = geocoder.distance.value;
          this.address.duration = geocoder.duration.value;
        }
        // console.log("this.address = ", JSON.stringify(this.address));
        sessionStorage.removeItem("apiGeocoder");
      } else {
        window.scrollTo(0, 0);
        this.snackbar.color = "warning";
        window.scrollTo(0, 0);
        this.snackbar.text = this.$t(
          "message.It was not possible to obtain the geolocation of this address"
        );
        this.snackbar.show = true;
        this.mainButton.btnSaveDisabled = false;
        return;
      }

      this.$store.commit("UPDATE_ENTERPRISES", this.enterprises);
      //
      this.loginUser();
    },

    ////////////////////
    async later(delay) {
      this.delay = this.delay * 2;
      return new Promise(function (resolve) {
        // console.log(" 2- delay = " + delay);
        setTimeout(resolve, delay);
      });
    },
    ///////////////
    checkQuote(e) {
      if (e.charCode == 34) e.preventDefault(); // double quotes
      if (e.charCode == 39) e.preventDefault(); // single quotes
    },

    ////////////////
    clearAddrees() {
      this.informUserAddress = false;
      this.groupId = "";
      this.companyId = "";

      //
      this.address.cepConsult = "";
      this.address.address = "";
      this.address.number = "";
      this.address.complement = "";
      this.address.neighborhood = "";
      this.address.city = "";
      this.address.state = "";
      this.address.postalCode = "";
    },

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ///  ROUTER  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //
    goBack() {
      this.$router.push({
        name: "userChangePassword",
        params: { pageId: "Login", email: this.email },
      });
    },

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ///  STORE  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    /// MESSAGE //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    snackbarTimeout(timeout) {
      this.snackbar.show = true;
      this.snackbar.timeout = timeout;
      setTimeout(() => {
        this.$router.push({ name: "menu" });
      }, this.snackbar.timeout);
    },
    ///////////////
    getUserInfo() {
      //
      if (window.location.href.toLowerCase().includes("localhost")) {
        // paid service 13 Euros per month
        getIp().then((response) => {
          // console.log(response);
          if (!response.data.erro) {
            this.userInfo.userIp = response.data.query;
            this.userInfo.country = response.data.country;
            this.userInfo.countryCode = response.data.countryCode;
            this.userInfo.region = response.data.region;
            this.userInfo.regionName = response.data.regionName;
            this.userInfo.city = response.data.city;
            this.userInfo.latitudeIp = response.data.lat;
            this.userInfo.longitudeIp = response.data.lon;
            // console.log(this.userInfo);
          } else {
            console.info(
              "Login: It was not possible to obtain the data from your IP"
            );
          }
        });
        // org -> only ip whith https
      } else {
        getIpify().then((response) => {
          // console.log(response);
          this.userInfo.userIp = response.data.ip;
        });
      }
      // platform.js
      this.userInfo.navigator = platform.name + " " + platform.version;
      if (platform.product) this.userInfo.device = platform.product; // ipad, sansung ..
      this.userInfo.operationalSystem =
        platform.os.family + " " + platform.os.version;
    },

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ///  API  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    async loginUser() {
      //
      if (!this.$refs.form.validate()) {
        // snackbar
        window.scrollTo(0, 0);
        this.snackbar.color = "warning";
        window.scrollTo(0, 0);
        this.snackbar.text = this.$t("message.alertTyping");
        this.snackbar.show = true;
        //
        return;
      }
      this.btnEnterDisabled = true;
      // setTimeout
      let delay = await this.later(this.delay);

      // console.log("groupIdOrigin : " + this.groupIdOrigin);
      let auth = {
        email: this.email,
        password: this.password,
        groupId: this.groupId,
        companyId: this.companyId,
        browserLocale: this.browserLocale,
        changePassword: false,
        //
        pageOrigin: this.pageOrigin,
        groupIdOrigin: this.groupIdOrigin,
        companyIdOrigin: this.companyIdOrigin,
        //
        appName: packageJson.name,
        userInfo: this.userInfo,
      };
      if (this.address.address) {
        auth.address = this.address;
      }
      // console.log("auth : " + JSON.stringify(auth));
      //
      api
        .post(`/login/`, auth)
        .then((response) => {
          // console.log(response.data);
          this.enterprises = response.data;
          // console.log("this.enterprises : " + JSON.stringify(this.enterprises));
          if (this.enterprises.length > 1) {
            this.btnEnterDisabled = false;
            this.dialog = true;
            return;
          }
          //
          this.$store.commit("UPDATE_USER", this.enterprises[0]);
          this.$store.commit("UPDATE_LOGIN", true);

          //  select //
          if (this.enterprises[0].companyId) {
            this.selectParameterAll();
          }

          // UserAddress
          if (this.informUserAddress) {
            // snackbar
            window.scrollTo(0, 0);
            this.snackbar.color = "success";
            this.snackbar.text =
              this.$t("address.Address saved: ") + this.address.address;
            this.snackbarTimeout(2000);
            return;
          }
          //
          this.$router.push({ name: "menu" });
        })
        .catch((err) => {
          // console.log(err);
          this.btnEnterDisabled = false;
          // snackbar
          err = err.data ? err.data : err;
          // console.log("err.response : " + JSON.stringify(err.response));
          if (err.response == undefined) {
            window.scrollTo(0, 0);
            this.snackbar.color = "error";
            this.snackbar.text = err + " => loginUser";
            this.snackbar.show = true;
          } else {
            if (err.response.status == 404) {
              window.scrollTo(0, 0);
              this.snackbar.color = "warning";
              this.snackbar.text = this.$t(
                "login.Invalid email or password(s)"
              );
              this.snackbar.show = true;
              //
            } else {
              if (err.response.status == 401) {
                window.scrollTo(0, 0);
                this.snackbar.color = "warning";
                this.snackbar.text = this.$t("login." + err.response.data);
                this.snackbar.show = true;
              } else {
                window.scrollTo(0, 0);
                this.snackbar.color = "error";
                this.snackbar.text = err + " => loginUser";
                this.snackbar.show = true;
              }
            }
          }
        });
    },

    // Update //
    logoutUser() {
      api
        .put(`/login/logout/?sessionId=${this.$store.state.user.sessionId}`)
        .then((response) => {
          // console.log(response.data);
        })
        .catch((err) => {
          // snackbar
          window.scrollTo(0, 0);
          this.snackbar.color = "error";
          this.snackbar.text = err + " => logout";
          this.snackbar.show = true;
          //
        });
    },

    //  selectParameterAll //
    selectParameterAll() {
      api
        .get(`/parameter/`)
        .then((response) => {
          this.$store.commit("UPDATE_PARAMETER", response.data);
          // console.log(JSON.stringify(this.$store.state.parameter));
        })
        .catch((err) => {
          // snackbar
          window.scrollTo(0, 0);
          this.snackbar.color = "error";
          this.snackbar.text = err + " => selectParameterAll";
          this.snackbar.show = true;
          //
        });
    },
  },

  //
  created() {
    document.title = "APP Gestão XXI";

    // clear sessionStorage
    sessionStorage.clear();

    if (localStorage.language) {
      this.indexLanguage = this.languages.findIndex(
        (e, index, array) => e.language === localStorage.language
      );
      const locale = this.languages.find(
        (e, index, array) => e.language === localStorage.language
      );
      // console.log(locale);
      this.changeLocale(locale);
    } else {
      this.changeLocale({
        flag: "br",
        locale: "pt-br",
        language: "Portuguese Brazil",
      });
      // this.showLanguage = true;
    }
    // console.log("this.indexLanguage = " + this.indexLanguage);

    // route footer logout //
    if (this.$route.params.option === "logout") {
      this.logoutUser();
      document.location.reload(false); // page reload whith cache
    }

    if (this.$route.params.pageOrigin) {
      this.pageOrigin = this.$route.params.pageOrigin;
      this.groupIdOrigin = this.$route.params.groupIdOrigin;
      this.companyIdOrigin = this.$route.params.companyIdOrigin;
    }
    sessionStorage.entity = "login";
    // Clear Vuex
    this.$store.replaceState({
      login: false,
      user: {
        userId: "",
      },
      parameter: {},
    });

    // clear localStorage --> version  /////////////////////////////////
    // console.log("localStorage.version = " + localStorage.version);

    if (
      !localStorage.version ||
      localStorage.version.substring(0, 5) !=
        packageJson.version.substring(0, 5)
    ) {
      let locale = localStorage.locale;
      let language = localStorage.language;
      //
      localStorage.clear();
      //
      localStorage.locale = locale;
      localStorage.language = language;
    }
    localStorage.version = packageJson.version;
    //
    this.counterAccount = localStorage.counterAccount;
    // get IP
    this.getUserInfo();
    this.userInfo.appVersion = packageJson.version;
  },
};
</script>

