<template>
  <div>
    <!-- NAVBAR-->
    <nav>
      <Navbar :navbar="navbar"></Navbar>
    </nav>

    <main :class="{
        'ml-12': $vuetify.breakpoint.mdAndUp,
        'ml-2': $vuetify.breakpoint.smAndDown,
        'me-12': $vuetify.breakpoint.mdAndUp,
        'me-2': $vuetify.breakpoint.smAndDown,
      }">
      <!-- TITLE -->
      <v-container fluid>
        <v-card
          flat
          class="mx-auto"
          max-width="600"
          tile
        >
          <v-row class="mt-4 mb-0">
            <v-col cols="6">
              <v-row>
                <v-img
                  src="@/assets/logo/logo_gestao_xxi_horizontal.jpg"
                  contain
                  max-height="70"
                ></v-img>
              </v-row>
            </v-col>
          </v-row>

          <v-row>
            <!-- New / Edit -->
            <h1 class="headline titleH1">
              {{ $t("common." + $route.meta.title) }}
            </h1>
          </v-row>
          <v-alert
            class="mt-6"
            type="info"
            border="left"
            text
            transition="slide-y-reverse-transition"
          >{{ $t("login.titleHelpNewAccountActivation") }}</v-alert>

          <!-- FORM -->
          <v-form
            v-model="formValid"
            ref="form"
          >
            <!-- ///////////////////////////////////////////////////////////////////////////////////////////////// -->
            <!-- snackbar -->
            <v-snackbar
              v-model="snackbar.show"
              top="top"
              :color="snackbar.color"
              :timeout="snackbar.timeout"
            >{{ snackbar.text }}</v-snackbar>

            <!-- DIALOG -->

            <!-- BUTTOM-->
            <v-card flat>
              <v-card
                class="mt-8 mb-6"
                flat
              >
                <v-col cols="12">
                  <v-btn
                    block
                    tile
                    outlined
                    color="primary"
                    :disabled="btnActivateDisabled"
                    @click.prevent="activateNewAccount()"
                  >
                    <div>
                      <!-- <v-icon left>lock_open</v-icon> -->
                      {{ $t("buttom.Activate") }}
                    </div>
                  </v-btn>
                </v-col>
              </v-card>
            </v-card>
          </v-form>
        </v-card>
      </v-container>
    </main>
  </div>
</template>

<script>
import i18n from "@/i18n.js";
//
import Navbar from "@/app/2_common/components/page/Navbar.vue";
import Dialog from "@/app/2_common/components/page/Dialog.vue";
import MainButton from "@/app/2_common/components/page/MainButton.vue";

// services / helpers
import { api } from "@/services.js";

export default {
  name: "NewAccountActivation",
  components: {
    //
    Navbar,
    Dialog,
    MainButton,
  },
  ////////
  data() {
    return {
      token: "",
      email: "",
      //
      // standard
      formValid: true,
      goBackOk: false,
      // Help
      titleHelpNewAccountActivation: true,
      btnActivateDisabled: false,
      // Setting / prefer
      //
      /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
      ///  PROPS     //////////////////////////////////////////////////////////////////////////////////////////////////////////////////
      /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
      /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
      ///  PROPS Dialog MainButton  Navbar  ///////////////////////////////////////////////////////////////////////////////////////////
      /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
      // Props Dialog
      dialog: {
        show: false,
        title: "dialog", // i18n
        text: "",
      },
      // Props MainButton
      mainButton: {
        btnSaveDisabled: false,
        btnCancelShow: false,
      },
      // Props Navbar
      navbar: {
        menu: [
          {
            text: "Help",
            icon: "mdi-help-circle-outline",
            action: "",
            route: "",
          },
        ],
      },
      // snackbar
      snackbar: {
        show: false,
        color: "",
        text: "",
        timeout: 3000,
      },
      /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
      ///  RULES     //////////////////////////////////////////////////////////////////////////////////////////////////////////////////
      /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
      rules: {
        required: (value) => !!value || this.$t("rules.Required Field"),
        //
      },
    };
  },
  //////////
  methods: {
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ///  JS     /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ///

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    /// NAVBAR //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ///  MAIN BUTTOM  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ///  ROUTER  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // goBack(
    goBack() {
      this.goBackOk = true;
      this.$router.push({ name: "login" });
    },

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ///  STORE  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    /// MESSAGE //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    snackbarTimeout(timeout) {
      this.mainButton.btnSaveDisabled = false;
      this.snackbar.show = true;
      this.snackbar.timeout = timeout;
      setTimeout(() => {
        this.goBack();
      }, this.snackbar.timeout);
    },

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ///  API  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    activateNewAccount() {
      this.btnActivateDisabled = true;
      let auth = {
        token: this.token,
        email: this.email,
      };
      //
      api
        .post(`/login/activateNewAccount`, auth)
        .then((response) => {
          // console.log(response.data);
          // snackbar
          window.scrollTo(0, 0);
          this.snackbar.color = "success";
          this.snackbar.text =
            this.$t("login.New Account Activation to: ") + response.data;
          this.snackbarTimeout(2000);
          //
          localStorage.clear();
          //
        })
        .catch((err) => {
          // console.log(err);
          this.btnActivateDisabled = false;
          // snackbar
          err = err.data ? err.data : err;
          // console.log("err.response  + " + err.response);
          window.scrollTo(0, 0);
          if (err.response == undefined) {
            this.snackbar.color = "error";
            this.snackbar.text = err + " => activateNewAccount";
            this.snackbar.show = true;
          } else {
            if (err.response.status == 404) {
              this.snackbar.color = "warning";
              this.snackbar.text = this.$t("login.msgActivateNewAccount");
              this.snackbar.show = true;
              //
            } else {
              this.snackbar.color = "error";
              this.snackbar.text = err + " => activateNewAccount";
              this.snackbar.show = true;
            }
          }
        });
    },
  },
  /////////////////
  ///////////
  created() {
    if (localStorage.locale != undefined) {
      i18n.locale = localStorage.locale;
    } else {
      i18n.locale = window.navigator.language.toLowerCase();
    }
    // console.log("i18n.locale = " + i18n.locale);

    document.title = this.$t("common." + this.$route.meta.title);
    //
    this.token = this.$route.query.t;
    this.email = this.$route.query.e;
    //
    localStorage.clear(); // old junk cleaner
  },
};
</script>
