import Vue from 'vue'
import Router from 'vue-router'
//

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//  MODULE ACCESS
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
import Login from '@/app/1_access/views/login/Login.vue'
import ForgotMyPassword from '@/app/1_access/views/login/ForgotMyPassword.vue'
import ResetPassword from '@/app/1_access/views/login/ResetPassword.vue'
import NewAccountActivation from '@/app/1_access/views/login/NewAccountActivation.vue'
import EmailValidation from '@/app/1_access/views/login/EmailValidation.vue'

// lazy
const User = () => import('@/app/1_access/views/user/User.vue')
const UserAccess = () => import('@/app/1_access/views/user/UserAccess.vue')
const UserLogin = () => import('@/app/1_access/views/user/UserLogin.vue')
const UserSelect = () => import('@/app/1_access/views/user/UserSelect.vue')
const UserChangePassword = () => import('@/app/1_access/views/user/UserChangePassword.vue')

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//  MODULE COMMON
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
import Menu from '@/app/2_common/views/Menu.vue'
import MenuModule from '@/app/2_common/views/MenuModule.vue'

// lazy
const Parameter = () => import('@/app/2_common/views/Parameter.vue')
const Setting = () => import('@/app/2_common/views/Setting.vue')
const Audit = () => import('@/app/2_common/views/Audit.vue')
const Orientation = () => import('@/app/2_common/views/Orientation.vue')
const error404 = () => import('@/app/2_common/views/error404.vue')
const ReportModel = () => import('@/app/2_common/views/ReportModel.vue')
const ReportStandard = () => import('@/app/2_common/views/ReportStandard.vue')
const ImageGallery = () => import('@/app/2_common/views/ImageGallery.vue')
const LayoutModel = () => import('@/app/2_common/views/LayoutModel.vue')
const LayoutImprint = () => import('@/app/2_common/views/LayoutImprint.vue')
const FollowUp = () => import('@/app/2_common/views/FollowUp.vue')
const Serial = () => import('@/app/2_common/views/Serial.vue')
const GridModel = () => import('@/app/2_common/views/GridModel.vue')
const SendEmail = () => import('@/app/2_common/views/SendEmail.vue')


//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//  MODULE COMPANY
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const Enterprise = () => import('@/app/3_company/views/enterprise/Enterprise.vue')
const EnterpriseContact = () => import('@/app/3_company/views/enterprise/EnterpriseContact.vue')
const EnterpriseAddress = () => import('@/app/3_company/views/enterprise/EnterpriseAddress.vue')
const EnterpriseDeliveryCharge = () => import('@/app/3_company/views/enterprise/EnterpriseDeliveryCharge.vue')
const EnterpriseDeliveryArea = () => import('@/app/3_company/views/enterprise/EnterpriseDeliveryArea.vue')

const EnterpriseBank = () => import('@/app/3_company/views/enterprise/EnterpriseBank.vue')
const EnterpriseModule = () => import('@/app/3_company/views/enterprise/EnterpriseModule.vue')
const EnterpriseSelect = () => import('@/app/3_company/views/enterprise/EnterpriseSelect.vue')

// const Foo = () => const ('./Foo.vue')
const Customer = () => import('@/app/3_company/views/customer/Customer.vue')
const CustomerContact = () => import('@/app/3_company/views/customer/CustomerContact.vue')
const CustomerAddress = () => import('@/app/3_company/views/customer/CustomerAddress.vue')
const CustomerBank = () => import('@/app/3_company/views/customer/CustomerBank.vue')
const CustomerCommercial = () => import('@/app/3_company/views/customer/CustomerCommercial.vue')
const CustomerSelect = () => import('@/app/3_company/views/customer/CustomerSelect.vue')

const Supplier = () => import('@/app/3_company/views/supplier/Supplier.vue')
const SupplierContact = () => import('@/app/3_company/views/supplier/SupplierContact.vue')
const SupplierAddress = () => import('@/app/3_company/views/supplier/SupplierAddress.vue')
const SupplierBank = () => import('@/app/3_company/views/supplier/SupplierBank.vue')
const SupplierSelect = () => import('@/app/3_company/views/supplier/SupplierSelect.vue')
//
const Employee = () => import('@/app/3_company/views/employee/Employee.vue')
const EmployeeContact = () => import('@/app/3_company/views/employee/EmployeeContact.vue')
const EmployeeAddress = () => import('@/app/3_company/views/employee/EmployeeAddress.vue')
const EmployeeBank = () => import('@/app/3_company/views/employee/EmployeeBank.vue')
const EmployeeSelect = () => import('@/app/3_company/views/employee/EmployeeSelect.vue')

//
const Seller = () => import('@/app/3_company/views/seller/Seller.vue')
const SellerSelect = () => import('@/app/3_company/views/seller/SellerSelect.vue')
//
const Team = () => import('@/app/3_company/views/team/Team.vue')
const TeamSelect = () => import('@/app/3_company/views/team/TeamSelect.vue')

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//  MODULE STOCK
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const Product = () => import('@/app/4_stock/views/product/Product.vue')
const ProductSupplier = () => import('@/app/4_stock/views/product/ProductSupplier.vue')
const ProductCustomer = () => import('@/app/4_stock/views/product/ProductCustomer.vue')
const ProductStock = () => import('@/app/4_stock/views/product/ProductStock.vue')
const ProductBatch = () => import('@/app/4_stock/views/product/ProductBatch.vue')
const ProductReference = () => import('@/app/4_stock/views/product/ProductReference.vue')
const ProductConsumer = () => import('@/app/4_stock/views/product/ProductConsumer.vue')
const ProductSelect = () => import('@/app/4_stock/views/product/ProductSelect.vue')
const Kit = () => import('@/app/4_stock/views/kit/Kit.vue')
const KitSelect = () => import('@/app/4_stock/views/kit/KitSelect.vue')

const Service = () => import('@/app/4_stock/views/service/Service.vue')
const ServiceSupplier = () => import('@/app/4_stock/views/service/ServiceSupplier.vue')
const ServiceCustomer = () => import('@/app/4_stock/views/service/ServiceCustomer.vue')
const ServiceStock = () => import('@/app/4_stock/views/service/ServiceStock.vue')
// const ServiceBatch = () => import('@/app/4_stock/views/service/ServiceBatch.vue')
const ServiceReference = () => import('@/app/4_stock/views/service/ServiceReference.vue')
const ServiceSelect = () => import('@/app/4_stock/views/service/ServiceSelect.vue')

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//  MODULE PURCHASE
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const Solicit = () => import('@/app/5_purchase/views/Solicit.vue')
const SolicitSelect = () => import('@/app/5_purchase/views/SolicitSelect.vue')
const Quotation = () => import('@/app/5_purchase/views/Quotation.vue')
const QuotationSelect = () => import('@/app/5_purchase/views/QuotationSelect.vue')
const QuotationSupplier = () => import('@/app/5_purchase/views/QuotationSupplier.vue')
const Order = () => import('@/app/5_purchase/views/Order.vue')
const OrderSelect = () => import('@/app/5_purchase/views/OrderSelect.vue')
const Receive = () => import('@/app/5_purchase/views/Receive.vue')
const ReceiveSelect = () => import('@/app/5_purchase/views/ReceiveSelect.vue')
const Back = () => import('@/app/5_purchase/views/Back.vue')
const BackSelect = () => import('@/app/5_purchase/views/BackSelect.vue')

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//  MODULE CONSUMER
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const Deliver = () => import('@/app/6_consumer/views/Deliver.vue')
const DeliverSelect = () => import('@/app/6_consumer/views/DeliverSelect.vue')
const Consumer = () => import('@/app/6_consumer/views/Consumer.vue')
const ConsumerSelect = () => import('@/app/6_consumer/views/ConsumerSelect.vue')
const Self = () => import('@/app/6_consumer/views/Self.vue')
const SelfSelect = () => import('@/app/6_consumer/views/SelfSelect.vue')

const Table = () => import('@/app/6_consumer/views/Table.vue')
const Command = () => import('@/app/6_consumer/views/Command.vue')

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//  MODULE COMMERCIAL
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const Quote = () => import('@/app/7_commercial/views/Quote.vue')
const QuoteSelect = () => import('@/app/7_commercial/views/QuoteSelect.vue')
const Sale = () => import('@/app/7_commercial/views/Sale.vue')
const SaleSelect = () => import('@/app/7_commercial/views/SaleSelect.vue')
const Expedition = () => import('@/app/7_commercial/views/Expedition.vue')
const ExpeditionSelect = () => import('@/app/7_commercial/views/ExpeditionSelect.vue')
const Devolution = () => import('@/app/7_commercial/views/Devolution.vue')
const DevolutionSelect = () => import('@/app/7_commercial/views/DevolutionSelect.vue')

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//  MODULE DELIVERY
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const Delivery = () => import('@/app/9_delivery/views/Delivery.vue')
const DeliverySelect = () => import('@/app/9_delivery/views/DeliverySelect.vue')
const DeliveryRoadmap = () => import('@/app/9_delivery/views/DeliveryRoadmap.vue')

Vue.use(Router)
// export default new Router({ ... comentado depois router.beforeEach
const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //  MODULE ACCESS
    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // login
    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    {
      path: '/',
      name: 'login',
      component: Login,
      meta: {
        auth: false,
        title: 'Login'
      }
    },
    {
      path: '/',
      name: 'forgotMyPassword',
      component: ForgotMyPassword,
      meta: {
        auth: false,
        title: 'Forgot My Password'
      }
    },
    {
      path: '/resetPassword',
      name: 'resetPassword',
      component: ResetPassword,
      meta: {
        auth: false,
        title: 'Reset Password'
      }
    },

    {
      path: '/newAccountActivation',
      name: 'newAccountActivation',
      component: NewAccountActivation,
      meta: {
        auth: false,
        title: 'New Account Activation'
      }
    },

    {
      path: '/emailValidation',
      name: 'emailValidation',
      component: EmailValidation,
      meta: {
        auth: false,
        title: 'Email Validation'
      }
    },

    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // user
    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    {
      path: '/user',
      name: 'userEdit', // deixar  'Edit'  antes do  'New' para prever retorno pelo botão do navegador
      component: User,
      meta: {
        auth: true,
        title: 'Edit User'
      }
    },
    {
      path: '/user',
      name: 'userNewAccount',
      component: User,
      meta: {
        auth: false, // new login
        title: 'New Account'
      }
    },
    {
      path: '/user',
      name: 'user',
      component: User,
      meta: {
        auth: true,
        title: 'New User'
      }
    },

    {
      path: '/access',
      name: 'userAccess',
      component: UserAccess,
      meta: {
        auth: true,
        title: 'User Accesses'
      }
    },
    {
      path: '/logins',
      name: 'userLogin',
      component: UserLogin,
      meta: {
        auth: true,
        title: 'User Logins'
      }
    },
    {
      path: '/users',
      name: 'UserSelect',
      params: {
        recent: false
      },
      component: UserSelect,
      meta: {
        auth: true,
        title: 'Users'
      }
    },
    {
      path: '/userChangePassword',
      name: 'userChangePassword',
      component: UserChangePassword,
      meta: {
        auth: false, // forgot
        title: 'Change Password'
      }
    },

    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //  MODULE COMMON
    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    {
      path: '/menu',
      name: 'menu',
      component: Menu,
      meta: {
        auth: true,
        title: 'Menu'
      }
    },

    {
      path: '/menu_',
      name: 'MenuModule',
      component: MenuModule,
      meta: {
        auth: true,
        title: 'Menu'
      }
    },

    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // Parameter
    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    {
      path: '/parameter',
      name: 'Parameter',
      component: Parameter,
      meta: {
        auth: true,
        title: 'Parameters'
      }
    },

    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // Setting
    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    {
      path: '/setting',
      name: 'setting',
      params: {
        settingId: ""
      },
      component: Setting,
      meta: {
        auth: true,
        title: 'Setting'
      },

    },

    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // Audit
    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    {
      path: '/audit',
      name: 'Audit',
      component: Audit,
      meta: {
        auth: true,
        title: 'Audit'
      }
    },

    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // Orientation
    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    {
      path: '/orientation',
      name: 'Orientation',
      component: Orientation,
      meta: {
        title: 'Orientation'
      }
    },

    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // error404
    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    {
      path: '*',
      name: 'error404',
      component: error404,
      meta: {
        auth: false,
        title: 'Page Not Found'
      }
    },

    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // ReportModel
    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    {
      path: '/report_model',
      name: 'ReportModel',
      component: ReportModel,
      meta: {
        auth: true,
        title: 'Model Report'
      }
    },

    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // Report Standard
    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    {
      path: '/report',
      name: 'reportStandard',
      params: {
        recent: false
      },
      component: ReportStandard,
      meta: {
        auth: true,
        title: 'Report'
      }
    },

    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // ImageGallery
    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    {
      path: '/images',
      name: 'imageGallery',
      component: ImageGallery,
      meta: {
        auth: true,
        title: 'Images'
      }
    },

    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // Layout Model
    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    {
      path: '/layout_model',
      name: 'LayoutModel',
      component: LayoutModel,
      meta: {
        auth: true,
        title: 'Layout'
      }
    },

    {
      path: '/layout_imprint',
      name: 'LayoutImprint',
      component: LayoutImprint,
      meta: {
        auth: true,
        title: 'Layout Imprint'
      }
    },


    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // Log
    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    {
      path: '/follow-up',
      name: 'FollowUp',
      component: FollowUp,
      meta: {
        auth: true,
        title: 'Follow-up'
      }
    },

    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // Serial
    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    {
      path: '/serial',
      name: 'Serial',
      component: Serial,
      meta: {
        auth: true,
        title: 'Serial'
      }
    },

    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // GridModel
    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    {
      path: '/grid-model',
      name: 'GridModel',
      component: GridModel,
      meta: {
        auth: true,
        title: 'Grid Model'
      }
    },


    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // SendEmail
    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    {
      path: '/email',
      name: 'SendEmail',
      component: SendEmail,
      meta: {
        auth: true,
        title: 'Send e-mail'
      }
    },



    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //  MODULE COMPANY
    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // enterprise
    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    {
      path: '/enterprise',
      name: 'enterpriseEdit', // deixar  'Edit'  antes do  'New' para prever retorno pelo botão do navegador
      component: Enterprise,
      meta: {
        auth: true,
        title: 'Edit Enterprise'
      }
    },
    {
      path: '/enterprise',
      name: 'enterprise',
      component: Enterprise,
      meta: {
        auth: true,
        title: 'New Enterprise'
      }
    },
    {
      path: '/contact',
      name: 'enterpriseContact',
      component: EnterpriseContact,
      meta: {
        auth: true,
        title: 'Enterprise Contacts'
      }
    },
    {
      path: '/address',
      name: 'enterpriseAddress',
      component: EnterpriseAddress,
      meta: {
        auth: true,
        title: 'Enterprise Addresses'
      }
    },

    {
      path: '/charge',
      name: 'enterpriseDeliveryCharge',
      component: EnterpriseDeliveryCharge,
      meta: {
        auth: true,
        title: 'Delivery Charge'
      }
    },

    {
      path: '/area',
      name: 'enterpriseDeliveryArea',
      component: EnterpriseDeliveryArea,
      meta: {
        auth: true,
        title: 'Delivery Area'
      }
    },


    {
      path: '/bank',
      name: 'enterpriseBank',
      component: EnterpriseBank,
      meta: {
        auth: true,
        title: 'Enterprise Banks'
      }
    },

    {
      path: '/enterprise_module',
      name: 'enterpriseModule',
      component: EnterpriseModule,
      meta: {
        auth: true,
        title: 'Company Modules'
      }
    },

    {
      path: '/enterprises',
      name: 'EnterpriseSelect',
      params: {
        recent: false
      },
      component: EnterpriseSelect,
      meta: {
        auth: true,
        title: 'Enterprises'
      }
    },

    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // customer
    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    {
      path: '/customer',
      name: 'customerEdit', // deixar  'Edit'  antes do  'New' para prever retorno pelo botão do navegador
      component: Customer,
      meta: {
        auth: true,
        title: 'Edit Customer'
      }
    },
    {
      path: '/customer',
      name: 'customer',
      component: Customer,
      meta: {
        auth: true,
        title: 'New Customer'
      }
    },
    {
      path: '/contact',
      name: 'customerContact',
      component: CustomerContact,
      meta: {
        auth: true,
        title: 'Customer Contacts'
      }
    },
    {
      path: '/address',
      name: 'customerAddress',
      component: CustomerAddress,
      meta: {
        auth: true,
        title: 'Customer Addresses'
      }
    },
    {
      path: '/bank',
      name: 'customerBank',
      component: CustomerBank,
      meta: {
        auth: true,
        title: 'Customer Banks'
      }
    },
    {
      path: '/customer_commercial',
      name: 'customerCommercial',
      component: CustomerCommercial,
      meta: {
        auth: true,
        title: 'Customer Commercial'
      }
    },

    {
      path: '/customers',
      name: 'CustomerSelect',
      params: {
        recent: false
      },
      component: CustomerSelect,
      meta: {
        auth: true,
        title: 'Customers'
      }
    },

    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // supplier
    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    {
      path: '/supplier',
      name: 'supplierEdit', // deixar  'Edit'  antes do  'New' para prever retorno pelo botão do navegador
      component: Supplier,
      meta: {
        auth: true,
        title: 'Edit Supplier'
      }
    },
    {
      path: '/supplier',
      name: 'supplier',
      component: Supplier,
      meta: {
        auth: true,
        title: 'New Supplier'
      }
    },
    {
      path: '/contact',
      name: 'supplierContact',
      component: SupplierContact,
      meta: {
        auth: true,
        title: 'Supplier Contacts'
      }
    },
    {
      path: '/address',
      name: 'supplierAddress',
      component: SupplierAddress,
      meta: {
        auth: true,
        title: 'Supplier Addresses'
      }
    },
    {
      path: '/bank',
      name: 'supplierBank',
      component: SupplierBank,
      meta: {
        auth: true,
        title: 'Supplier Banks'
      }
    },
    {
      path: '/suppliers',
      name: 'SupplierSelect',
      params: {
        recent: false
      },
      component: SupplierSelect,
      meta: {
        auth: true,
        title: 'Suppliers'
      }
    },

    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // employee
    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    {
      path: '/employee',
      name: 'employeeEdit', // deixar  'Edit'  antes do  'New' para prever retorno pelo botão do navegador
      component: Employee,
      meta: {
        auth: true,
        title: 'Edit Employee'
      }
    },
    {
      path: '/employee',
      name: 'employee',
      component: Employee,
      meta: {
        auth: true,
        title: 'New Employee'
      }
    },
    {
      path: '/contact',
      name: 'employeeContact',
      component: EmployeeContact,
      meta: {
        auth: true,
        title: 'Employee Contacts'
      }
    },
    {
      path: '/address',
      name: 'employeeAddress',
      component: EmployeeAddress,
      meta: {
        auth: true,
        title: 'Employee Addresses'
      }
    },
    {
      path: '/bank',
      name: 'employeeBank',
      component: EmployeeBank,
      meta: {
        auth: true,
        title: 'Employee Banks'
      }
    },
    {
      path: '/employees',
      name: 'EmployeeSelect',
      params: {
        recent: false
      },
      component: EmployeeSelect,
      meta: {
        auth: true,
        title: 'Employees'
      }
    },

    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // seller
    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    {
      path: '/seller',
      name: 'sellerEdit', // deixar  'Edit'  antes do  'New' para prever retorno pelo botão do navegador
      component: Seller,
      meta: {
        auth: true,
        title: 'Edit Seller'
      }
    },
    {
      path: '/seller',
      name: 'seller',
      component: Seller,
      meta: {
        auth: true,
        title: 'New Seller'
      }
    },

    {
      path: '/sellers',
      name: 'SellerSelect',
      params: {
        recent: false
      },
      component: SellerSelect,
      meta: {
        auth: true,
        title: 'Sellers'
      }
    },


    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // team
    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    {
      path: '/team',
      name: 'teamEdit', // deixar  'Edit'  antes do  'New' para prever retorno pelo botão do navegador
      component: Team,
      meta: {
        auth: true,
        title: 'Edit Resource'
      }
    },
    {
      path: '/team',
      name: 'team',
      component: Team,
      meta: {
        auth: true,
        title: 'New Resource'
      }
    },

    {
      path: '/teams',
      name: 'TeamSelect',
      params: {
        recent: false
      },
      component: TeamSelect,
      meta: {
        auth: true,
        title: 'Resources'
      }
    },


    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //  MODULE STOCK
    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    {
      path: '/product',
      name: 'productEdit',
      component: Product,
      meta: {
        auth: true,
        title: 'Edit Product'
      }
    },
    {
      path: '/product',
      name: 'product',
      component: Product,
      meta: {
        auth: true,
        title: 'New Product'
      }
    },
    {
      path: '/product_supplier',
      name: 'productSupplier',
      component: ProductSupplier,
      meta: {
        auth: true,
        title: 'Product Suppliers'
      }
    },
    {
      path: '/product_customer',
      name: 'productCustomer',
      component: ProductCustomer,
      meta: {
        auth: true,
        title: 'Prices Selling'
      }
    },
    {
      path: '/product_stock',
      name: 'productStock',
      component: ProductStock,
      meta: {
        auth: true,
        title: 'Stocks'
      }
    },
    {
      path: '/product_batch',
      name: 'productBatch',
      component: ProductBatch,
      meta: {
        auth: true,
        title: 'Batches'
      }
    },

    {
      path: '/product_consumer',
      name: 'productConsumer',
      component: ProductConsumer,
      meta: {
        auth: true,
        title: 'Product - Consumer Sales'
      }
    },

    {
      path: '/product_reference',
      name: 'productReference',
      component: ProductReference,
      meta: {
        auth: true,
        title: 'References'
      }
    },

    {
      path: '/products',
      name: 'ProductSelect',
      params: {
        recent: false
      },
      component: ProductSelect,
      meta: {
        auth: true,
        title: 'Products'
      }
    },

    {
      path: '/kit',
      name: 'kitEdit',
      component: Kit,
      meta: {
        auth: true,
        title: 'Edit Kit'
      }
    },
    {
      path: '/kit',
      name: 'kit',
      component: Kit,
      meta: {
        auth: true,
        title: 'New Kit or Combo'
      }
    },

    {
      path: '/kits',
      name: 'KitSelect',
      params: {
        recent: false
      },
      component: KitSelect,
      meta: {
        auth: true,
        title: 'Kits and Combos'
      }
    },

    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // service
    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    {
      path: '/service',
      name: 'serviceEdit',
      component: Service,
      meta: {
        auth: true,
        title: 'Edit Service'
      }
    },
    {
      path: '/service',
      name: 'service',
      component: Service,
      meta: {
        auth: true,
        title: 'New Service'
      }
    },
    {
      path: '/service_supplier',
      name: 'serviceSupplier',
      component: ServiceSupplier,
      meta: {
        auth: true,
        title: 'Service Suppliers'
      }
    },
    {
      path: '/service_customer',
      name: 'serviceCustomer',
      component: ServiceCustomer,
      meta: {
        auth: true,
        title: 'Prices Selling'
      }
    },
    {
      path: '/service_stock',
      name: 'serviceStock',
      component: ServiceStock,
      meta: {
        auth: true,
        title: 'Stocks'
      }
    },

    {
      path: '/service_reference',
      name: 'serviceReference',
      component: ServiceReference,
      meta: {
        auth: true,
        title: 'References'
      }
    },

    {
      path: '/services',
      name: 'ServiceSelect',
      params: {
        recent: false
      },
      component: ServiceSelect,
      meta: {
        auth: true,
        title: 'Services'
      }
    },


    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //  MODULE PURCHASE
    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    {
      path: '/solicit',
      name: 'solicitEdit',
      component: Solicit,
      meta: {
        auth: true,
        title: 'Edit Purchase Solicit'
      }
    },
    {
      path: '/solicit',
      name: 'solicit',
      component: Solicit,
      meta: {
        auth: true,
        title: 'New Purchase Solicit'
      }
    },
    {
      path: '/solicits',
      name: 'SolicitSelect',
      params: {
        recent: false
      },
      component: SolicitSelect,
      meta: {
        auth: true,
        title: 'Purchase Solicitations'
      }
    },

    {
      path: '/quotation',
      name: 'quotationEdit',
      component: Quotation,
      meta: {
        auth: true,
        title: 'Edit Purchase Quotation'
      }
    },
    {
      path: '/quotation',
      name: 'quotation',
      component: Quotation,
      meta: {
        auth: true,
        title: 'New Purchase Quotation'
      }
    },
    {
      path: '/quotations',
      name: 'QuotationSelect',
      params: {
        recent: false
      },
      component: QuotationSelect,
      meta: {
        auth: true,
        title: 'Purchase Quotations'
      }
    },

    {
      path: '/quotation_supplier',
      name: 'quotationSupplierEdit',
      component: QuotationSupplier,
      meta: {
        auth: true,
        title: 'Edit Supplier Quotation'
      }
    },

    {
      path: '/order',
      name: 'orderEdit',
      component: Order,
      meta: {
        auth: true,
        title: 'Edit Purchase Order'
      }
    },
    {
      path: '/order',
      name: 'order',
      component: Order,
      meta: {
        auth: true,
        title: 'New Purchase Order'
      }
    },
    {
      path: '/orders',
      name: 'OrderSelect',
      params: {
        recent: false
      },
      component: OrderSelect,
      meta: {
        auth: true,
        title: 'Purchase Orders'
      }
    },

    {
      path: '/receive',
      name: 'receiveEdit',
      component: Receive,
      meta: {
        auth: true,
        title: 'Edit Purchase Receiving'
      }
    },
    {
      path: '/receive',
      name: 'receive',
      component: Receive,
      meta: {
        auth: true,
        title: 'New Purchase Receiving'
      }
    },
    {
      path: '/receives',
      name: 'ReceiveSelect',
      params: {
        recent: false
      },
      component: ReceiveSelect,
      meta: {
        auth: true,
        title: 'Purchases Receivals'
      }
    },

    {
      path: '/back',
      name: 'backEdit',
      component: Back,
      meta: {
        auth: true,
        title: 'Edit Purchase Return'
      }
    },

    {
      path: '/back',
      name: 'back',
      component: Back,
      meta: {
        auth: true,
        title: 'New Purchase Return'
      }
    },
    {
      path: '/backs',
      name: 'BackSelect',
      params: {
        recent: false
      },
      component: BackSelect,
      meta: {
        auth: true,
        title: 'Purchases Back'
      }
    },



    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //  MODULE CONSUMER
    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    {
      path: '/deliver',
      name: 'deliverEdit',
      component: Deliver,
      meta: {
        auth: true,
        title: 'Edit Delivery Order'
      }
    },

    {
      path: '/deliver',
      name: 'deliver',
      component: Deliver,
      meta: {
        auth: false,
        title: 'New Delivery Order'
      }
    },

    {
      path: '/delivers',
      name: 'DeliverSelect',
      params: {
        recent: false
      },
      component: DeliverSelect,
      meta: {
        auth: true,
        title: 'Delivery Orders'
      }
    },

    {
      path: '/consumer',
      name: 'consumerEdit',
      component: Consumer,
      meta: {
        auth: true,
        title: 'Edit Consumer Sale'
      }
    },

    {
      path: '/consumer',
      name: 'consumer',
      component: Consumer,
      meta: {
        auth: false,
        title: 'New Consumer Sale'
      }
    },

    {
      path: '/consumers',
      name: 'ConsumerSelect',
      params: {
        recent: false
      },
      component: ConsumerSelect,
      meta: {
        auth: true,
        title: 'Consumer Sales'
      }
    },


    {
      path: '/self',
      name: 'selfEdit',
      component: Self,
      meta: {
        auth: true,
        title: 'Edit Self Service'
      }
    },

    {
      path: '/self',
      name: 'self',
      component: Self,
      meta: {
        auth: false,
        title: 'New Self Service'
      }
    },

    {
      path: '/selfs',
      name: 'SelfSelect',
      params: {
        recent: false
      },
      component: SelfSelect,
      meta: {
        auth: true,
        title: 'Self Service'
      }
    },

    {
      path: '/table',
      name: 'Table',
      params: {
        recent: false
      },
      component: Table,
      meta: {
        auth: true,
        title: 'Tables'
      }
    },

    {
      path: '/command',
      name: 'Command',
      params: {
        recent: false
      },
      component: Command,
      meta: {
        auth: true,
        title: 'Commands'
      }
    },

    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //  MODULE COMMERCIAL
    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    {
      path: '/quote',
      name: 'quoteEdit',
      component: Quote,
      meta: {
        auth: true,
        title: 'Edit Customer Quote'
      }
    },
    {
      path: '/quote',
      name: 'quote',
      component: Quote,
      meta: {
        auth: true,
        title: 'New Customer Quote'
      }
    },
    {
      path: '/quotes',
      name: 'QuoteSelect',
      params: {
        recent: false
      },
      component: QuoteSelect,
      meta: {
        auth: true,
        title: 'Customer Quotes'
      }
    },


    {
      path: '/sale',
      name: 'saleEdit',
      component: Sale,
      meta: {
        auth: true,
        title: 'Edit Customer Order'
      }
    },
    {
      path: '/sale',
      name: 'sale',
      component: Sale,
      meta: {
        auth: true,
        title: 'New Customer Order'
      }
    },
    {
      path: '/sales',
      name: 'SaleSelect',
      params: {
        recent: false
      },
      component: SaleSelect,
      meta: {
        auth: true,
        title: 'Customer Orders'
      }
    },

    {
      path: '/expedition',
      name: 'expeditionEdit',
      component: Expedition,
      meta: {
        auth: true,
        title: 'Edit Customer Expedition'
      }
    },
    {
      path: '/expedition',
      name: 'expedition',
      component: Expedition,
      meta: {
        auth: true,
        title: 'New Expedition'
      }
    },
    {
      path: '/expeditions',
      name: 'ExpeditionSelect',
      params: {
        recent: false
      },
      component: ExpeditionSelect,
      meta: {
        auth: true,
        title: 'Customer Expeditions'
      }
    },

    {
      path: '/devolution',
      name: 'devolutionEdit',
      component: Devolution,
      meta: {
        auth: true,
        title: 'Edit Customer Return'
      }
    },

    {
      path: '/devolution',
      name: 'devolution',
      component: Devolution,
      meta: {
        auth: true,
        title: 'New Devolution'
      }
    },
    {
      path: '/devolutions',
      name: 'DevolutionSelect',
      params: {
        recent: false
      },
      component: DevolutionSelect,
      meta: {
        auth: true,
        title: 'Customer Returns'
      }
    },


    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //  MODULE DELIVERY
    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    {
      path: '/delivery',
      name: 'deliveryEdit',
      component: Delivery,
      meta: {
        auth: true,
        title: 'Edit Delivery'
      }
    },
    {
      path: '/delivery',
      name: 'delivery',
      component: Delivery,
      meta: {
        auth: true,
        title: 'New Delivery'
      }
    },
    {
      path: '/deliveries',
      name: 'DeliverySelect',
      params: {
        recent: false
      },
      component: DeliverySelect,
      meta: {
        auth: true,
        title: 'Deliveries'
      }
    },

    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // DeliveryRoadmap
    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    {
      path: '/route',
      name: 'DeliveryRoadmap',
      component: DeliveryRoadmap,
      meta: {
        auth: true,
        title: 'Delivery Roadmap'
      }
    },







  ],

  scrollBehavior() {
    return window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }
})

router.beforeEach((to, from, next) => {
  // console.log(to)
  document.title = to.meta.title
  next()
})

export default router
